<template>
  <div class="px-4" v-if="isShow && !isPaymentPage">
    <ion-card class="mx-0 mt-4 px-2 py-2 credit-card">
      <ion-list-header class="px-0">
        <div class="text-gray-600 font-style-action-sheet-button-bold">
          {{ $t('card_list') }} {{ currentActiveCompany ? $t('for') : null }}
          <ion-text class="text-primary">{{ companyName }}</ion-text>
        </div>
      </ion-list-header>

      <ion-list class="card-list mt-2">
        <div v-if="listNetsCard && listNetsCard.length > 0">
          <nets-card-item @showAddNetsBankCard="showAddNetsBankCard" :isAccoutPage="true"> </nets-card-item>
        </div>

        <div v-if="listCardSortDefault && listCardSortDefault.length > 0">
          <card-item
            v-for="item in listCardSortDefault"
            :key="item.id"
            :card="item"
            :isDefault="item.id === defaultCardId"
            @open-modal-edit="handleOpenModalEditCard"
            :isNetCard="listNetsCard.length > 0"
          >
          </card-item>
        </div>

        <div
          v-if="isShowNets"
          :class="['add-box', listCardSortDefault && listCardSortDefault.length > 0 ? 'mt-2' : '']"
        >
          <div class="d-flex justify-space-between add-box-title">
            <div class="d-flex align-center">
              <ion-icon slot="icon-only " :icon="cardOutline" class="text-primary" />
              <ion-text class="text-green-primary font-style-toolbar-button-semibold ml-1">{{
                $t('accountPage.add_nets_card_type')
              }}</ion-text>
            </div>
            <ion-button fill="clear" @click="addNetsBankCard">
              <ion-icon slot="icon-only" :icon="addCircleOutline" class="text-primary" />
            </ion-button>
          </div>
          <div class="d-flex">
            <div class="d-flex justify-center mt-2">
              <ion-text>i</ion-text>
            </div>
            <ion-text class="ml-1 mt-2 font-style-tab-bar">
              {{ $t('this_will_be_added_to_all_user') }}
            </ion-text>
          </div>
        </div>

        <div class="add-box mt-2">
          <div class="d-flex justify-space-between add-box-title">
            <div class="d-flex align-center">
              <ion-icon slot="icon-only" :icon="cardOutline" class="text-primary" />
              <ion-text class="text-green-primary font-style-toolbar-button-semibold ml-1">{{
                $t('add_card_or_credit_card')
              }}</ion-text>
            </div>

            <ion-button fill="clear" @click="handleOpenModalAddCard">
              <ion-icon slot="icon-only" :icon="addCircleOutline" class="text-primary" />
            </ion-button>
          </div>
        </div>

        <ion-row v-if="propsReactive.cardMainBranch && !computedRef.isSelectMainBranch">
          <ion-col size="12" class="ion-no-padding mt-4 mb-2">
            <ion-text class="d-block text-gray-900 font-style-chip mt-1"
              >{{ selectedCustomer.name }} ({{ $t('main_branch') }})</ion-text
            >
          </ion-col>

          <ion-col size="12">
            <div>
              <card-item v-for="item in propsReactive.cardMainBranch" :key="item.id" :card="item">
              </card-item>
            </div>
          </ion-col>
        </ion-row>
      </ion-list>
    </ion-card>

    <!-- modal add card -->
    <ion-modal
      :is-open="isOpenModalAddRef"
      :css-class="propsReactive.isEdit ? 'modal-edit-card' : 'modal-add-card'"
      @didDismiss="setOpenModalAdd(false)"
    >
      <modal-add-card
        :isEdit="propsReactive.isEdit"
        :infoCard="propsReactive.infoCard"
        :isNetCard="listNetsCard.length > 0"
        :isDefaultCard="propsReactive.isDefaultCard"
        @add-card="addCard"
        @edit-card="handleSetDefaultCard"
        @remove-card="showRemoveCardConfirm"
        @close-modal="setOpenModalAdd(false)"
      ></modal-add-card>
    </ion-modal>

    <ion-loading
      :is-open="isOpenLoadingref"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
import { addStripeCard, getListCustomerCard } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { TOAST_COLOR } from '@/modules/shared/constants/';
import { openToast, presentAlert, useLoading } from '@/modules/shared/utils/';
import handleCallApi from '@/modules/shared/utils/services.js';
// import Payment from '@/plugins/nets-payment-plugin.js';
import { Device } from '@capacitor/device';
import { addCircleOutline, cardOutline } from 'ionicons/icons';
import { computed, defineComponent, reactive, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import CardItem from './partials/CardItem.vue';
import ModalAddCard from './partials/ModalAddCard.vue';
import NetsCardItem from './partials/NetsCardItem.vue';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/payment');

export default defineComponent({
  name: 'CardList',
  components: {
    CardItem,
    NetsCardItem,
    ModalAddCard
  },
  props: {
    currentActiveCompany: {
      type: Object,
      default: () => {}
    },
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      contentSuccessRemoveCard: this.$t('accountPage.card_has_been_removed'),
      contentSuccessSetDefault: this.$t('accountPage.default_card_has_been_changed'),
      toastMessage: '',
      toastColor: 'primary',
      isShowNets: false,
      user: [],
      deviceId: ''
    };
  },
  inject: ['$storage'],
  setup() {
    const { createLoading, dismissLoading } = useLoading();
    const isPaymentPage = ref(false);
    const isShow = ref(false);
    const isOpenModalAddRef = ref(false);
    const isOpenModalRemoveRef = ref(false);
    const setOpenModalRemove = (state) => (isOpenModalRemoveRef.value = state);
    const isOpenModalSuccessRef = ref(false);
    const setOpenModalSuccess = (state) => (isOpenModalSuccessRef.value = state);
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const selectedCompany = ref(null);
    const selectedCustomer = ref(null);
    const computedRef = reactive({
      isSelectMainBranch: computed(() => {
        return selectedCompany.value?.name === selectedCustomer.value?.name;
      })
    });
    const propsReactive = reactive({
      isEdit: false,
      infoCard: null,
      isDefaultCard: false
    });
    const setOpenModalAdd = (state) => (isOpenModalAddRef.value = state);
    return {
      cardOutline,
      addCircleOutline,
      close,
      setOpenModalAdd,
      isOpenModalAddRef,
      setOpenModalRemove,
      isOpenModalRemoveRef,
      setOpenModalSuccess,
      isOpenModalSuccessRef,
      setOpenLoading,
      isOpenLoadingref,
      isPaymentPage,
      selectedCompany,
      selectedCustomer,
      computedRef,
      isShow,
      createLoading,
      dismissLoading,
      propsReactive,
      handleCallApi,
      presentAlert
    };
  },

  async created() {
    this.deviceId = (await Device.getId()).uuid;
    await this.handleGetData();
  },
  computed: {
    ...mapGetters(['cardList', 'defaultCardId', 'loading', 'error', 'status', 'listNetsCard']),
    listCardSortDefault() {
      let listCardSort = [...this.cardList];
      const defaultCard = listCardSort.find((item) => item.id === this.defaultCardId);
      if (defaultCard) {
        listCardSort = listCardSort.filter((item) => item.id !== this.defaultCardId);
        listCardSort.unshift(defaultCard);
      }
      return listCardSort;
    },
    companyName() {
      return this.currentActiveCompany.name || this.currentActiveCompany.branch;
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_LIST_CUSTOMER_CARD,
      ACTIONS.GET_LIST_NETS_CARD,
      ACTIONS.GET_NETS_MERCHANT_TOKEN,
      ACTIONS.GET_DEFAULT_CARD,
      ACTIONS.SET_DEFAULT_CARD,
      ACTIONS.ADD_STRIPE_CARD,
      ACTIONS.REMOVE_CARD
    ]),
    handleGetData() {
      Promise.allSettled([
        this.$storage.getUser(),
        this.$storage.getSelectedCompany(),
        this.$storage.getSelectCustomer()
      ])
        .then((value) => {
          this.user = value[0].value;
          this.selectedCompany = value[1].value;
          this.selectedCustomer = value[2].value;
        })
        .then(() => {
          Promise.allSettled([this.handleGetCard(), this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId)]).then(
            () => {
              this.isShow = this.user.is_b2c;
              // this.isShowNets =
              //   isPlatform('mobile') &&
              //   (!this.listNetsCard || this.listNetsCard.length === 0) &&
              //   this.user &&
              //   this.user.country.name === 'SG';
              this.isShowNets = false; // TODO hard code to hide NETS bank card for 0.7.0
            }
          );
        });
    },
    handleOpenModalEditCard(infoCard, isDefault) {
      this.propsReactive.isEdit = true;
      this.propsReactive.infoCard = infoCard;
      this.propsReactive.isDefaultCard = isDefault;
      this.setOpenModalAdd(true);
    },
    handleOpenModalAddCard() {
      this.propsReactive.isEdit = false;
      this.propsReactive.infoCard = null;
      this.setOpenModalAdd(true);
    },
    showAddNetsBankCard() {
      this.isShowNets = false; // TODO hard code to hide NETS card payment for release 0.6.0
    },
    async showRemoveCardConfirm(cardId) {
      await this.setOpenModalAdd(false);
      await this.presentAlert({
        mode: 'ios',
        header: 'Delete Card?',
        buttons: [
          {
            text: 'Cancel'
          },
          {
            text: 'Ok',
            handler: () => {
              this.removeCard(cardId);
            }
          }
        ]
      });
    },
    async removeCard(cardId) {
      this.setOpenModalRemove(false);
      this.setOpenLoading(true);

      await this[ACTIONS.REMOVE_CARD]({
        customerId: this.selectedCompany.id,
        cardId: cardId
      });

      this.setOpenLoading(false);
      // check status
      let payload;
      if (this.status) {
        payload = {
          color: TOAST_COLOR.BLACK,
          message: this.$t('card_has_been_deleted')
        };
        await this.handleGetCard();
      } else {
        payload = {
          color: TOAST_COLOR.DANGER,
          message: this.error.message
        };
      }
      await openToast(payload);
    },
    async handleSetDefaultCard(cardId, isDefault) {
      await this.setOpenModalAdd(false);
      // show loading
      if (isDefault) {
        this.setOpenLoading(true);
        let payload;
        await this[ACTIONS.SET_DEFAULT_CARD]({
          cardId: cardId,
          customerId: this.selectedCompany.id
        });

        // hide loading
        this.setOpenLoading(false);

        if (this.status) {
          // show modal success
          payload = {
            color: TOAST_COLOR.BLACK,
            message: this.$t('card_has_been_updated')
          };
          await this.handleGetCard();
        } else {
          payload = {
            color: TOAST_COLOR.DANGER,
            message: this.error.message
          };
        }
        await openToast(payload);
      }
    },
    async addCard(card, isDefault) {
      this.setOpenModalAdd(false);
      await this.createLoading();
      const customerId = {
        customerId: this.selectedCompany.id
      };
      const params = { ...card, ...customerId };
      const payload = {
        isQuery: false,
        services: addStripeCard,
        variables: params
      };
      let toastMessage;
      try {
        const data = await handleCallApi(payload);
        const err = data?.addCardByCustomer?.message ?? false;
        if (err) {
          toastMessage = {
            message: data?.addCardByCustomer?.message ?? '',
            color: TOAST_COLOR.DANGER
          };
        } else {
          toastMessage = {
            color: TOAST_COLOR.BLACK,
            message: this.$t('accountPage.added_card_successfully')
          };
        }
        if (isDefault)
          await this[ACTIONS.SET_DEFAULT_CARD]({
            cardId: data.addCardByCustomer.cardId,
            customerId: this.selectedCompany.id
          });
        await this.handleGetCard();
      } catch (e) {
        toastMessage = {
          message: e,
          color: TOAST_COLOR.DANGER
        };
      } finally {
        await this.dismissLoading();
        await openToast(toastMessage);
      }
    },
    async handleGetCard() {
      Promise.all([
        this[ACTIONS.GET_LIST_CUSTOMER_CARD](this.selectedCompany.id),
        this[ACTIONS.GET_DEFAULT_CARD](this.selectedCompany.id),
        this.handleGetCardMainBranch()
      ]);
    },
    async handleGetCardMainBranch() {
      if (!this.computedRef.isSelectMainBranch) {
        const data = await handleCallApi({
          isQuery: true,
          services: getListCustomerCard,
          variables: {
            customerId: this.selectedCustomer.id
          }
        });
        this.propsReactive.cardMainBranch = data.listCardByCustomerId.data;
      }
    },
    async addNetsBankCard() {
      // let errorMessage = '';
      // try {
      //   const data = await Payment.onRegistration({
      //     mid: process.env.VUE_APP_NETS_MERCHANT_ID,
      //     muid: `${this.user.id}`
      //   });
      //   if (data.isSuccess) {
      //     const res = await this[ACTIONS.GET_NETS_MERCHANT_TOKEN]({
      //       t0102: data.result,
      //       deviceId: this.deviceId
      //     });
      //     if (res) {
      //       await this[ACTIONS.GET_LIST_NETS_CARD](this.deviceId);
      //       this.isShowNets = !this.listNetsCard || this.listNetsCard.length === 0;
      //     } else {
      //       errorMessage = this.error.message;
      //     }
      //   } else {
      //     errorMessage = data.result;
      //   }
      //   if (errorMessage) {
      //     const alert = await alertController.create({
      //       header: 'NETs payment error',
      //       message: errorMessage,
      //       buttons: [this.$t('close')]
      //     });
      //     await alert.present();
      //   }
      // } catch (error) {
      //   const alert = await alertController.create({
      //     header: 'on registration',
      //     message: `ERROR ${JSON.stringify(error)}`,
      //     buttons: [this.$t('close')]
      //   });
      //   await alert.present();
      // }
    }
  }
});
</script>

<style lang="scss" scoped>
.credit-card {
  box-shadow: 0 0 3px 1px #f0f0f0;
}
.credit-card .title {
  font-weight: 500;
  color: #555555;
  margin-bottom: 10px;
  font-size: 16px;
}

.add-box .col {
  padding-top: 10px;
}
.add-box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-success-25);
  border-radius: 4px;
  min-height: 56px;
  padding: 5px 16px 5px 16px;
  & > .add-box-title > div {
    & > ion-icon {
      font-size: 20px;
    }
  }

  & > div:nth-child(2) {
    border-top: 1px solid var(--ion-color-text-gray-300);
    & > div:nth-child(1) {
      min-width: 16px;
      height: 16px;
      border: 1px solid #eb8c31;
      font-size: 13px;
      border-radius: 20px;
      & > ion-text {
        border-radius: 50px;
        color: #eb8c31;
      }
    }
  }
}
</style>
